@import "../../variables";

#root {
  #feedback {
    margin-top: 40px;

    .ant-card {
      border-radius: 5px;
      border: 2px solid #eeeeee;

      .ant-card-head {
        background: #eeeeee;
        font-style: italic;
      }
    }
  }
}
