$screen-small: 768px;
$screen-regular: 769px;
$screen-large: 1200px;

$black: #000000;
$white: #ffffff;
$cacao: #673016;
$bg-grey: #eeeeee;
$border-grey: #eaecec;

$padding-container-x: 85px;
$padding-container-y: 24px;

$font-family: "Open Sans", sans-serif;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
