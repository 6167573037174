@import "../../variables";

#root {
  #auth {
    // min-height: calc(100vh - 10rem);

    .ant-space.ant-space-vertical {
      width: 100%;
      position: relative;
    }

    .auth-landing {
      // position: relative;
      height: 30vh;
      background-image: url("../../../public/images/cacao-seeds.jpg");
      background-size: cover;
      background-position: 50%;
    }

    .auth-form-container {
      margin: auto;
      padding: 0;
      padding-top: 6rem;
      width: 100%;
      // position: absolute;
      // top: 47%;

      .brand-wrapper {
        padding-left: 85px;
      }

      .children-wrapper {
        padding-right: 85px;
      }

      .title {
        color: $cacao;
      }

      .form-wrapper {
        margin-bottom: 10px;

        .ant-form-item-with-help {
          margin-bottom: 12px;
        }
      }
    }

    .checkbox-criteria {
      margin-bottom: 20px;
    }

    h2 {
      font-weight: $font-weight-bold;
      color: $cacao;
    }

    .ant-alert {
      width: 100%;
      margin-bottom: 10px;
    }

    .data-security-provisions-doc-info {
      font-size: 14px;
    }
  }
}
