@import "../../variables";

#root {
  #survey-editor {
    .ant-tabs-nav {
      border-bottom: 1px solid #777777;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $black;
    }

    .card-wrapper {
      border-radius: 8px;
    }
  }
}
