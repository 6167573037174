@import "../../variables.scss";

#root {
  #definition {
    .definition-wrapper {
      border-top: 1px solid $cacao;
      padding: 24px 5px;

      .title {
        color: $cacao;
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }
}
