@import "variables";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

#root {
  font-family: $font-family;
  font-size: 1rem;

  .container {
    padding: $padding-container-y $padding-container-x;
    min-height: calc(100vh - 10rem);
  }

  .assignment-notification-panel {
    padding: $padding-container-y $padding-container-x;
    border-bottom: 0.1rem solid #eaecec;
  }

  .bg-grey {
    background-color: $bg-grey !important;
  }
  th.bg-grey {
    background-color: #fafafa;
  }

  .custom-dropdown-wrapper {
    width: 15rem;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }
}

// for #root and modal
.field-help {
  margin-bottom: 12px;
}

.field-wrapper {
  .field-label {
    padding-left: 4px;
    margin-bottom: 10px;
  }
}

.data-security-modal-wrapper {
  h2,
  h3 {
    color: $cacao;
  }
}

.validation-popup {
  .ant-modal-title {
    color: #ff8b8c;
  }
}

// for Mark WIP functionalities
.dev {
  border: 1px solid red;
  color: red !important;
  padding: 0 4px;
}
